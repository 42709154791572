<template>
    <div
        :class="
            antlerClass('', {
                bundle: isBundleRow,
                break: subject && subject.id === 'break'
            })
        "
    >
        <div :class="antlerClass('body')">
            <div :class="antlerClass('top')">
                <div v-if="!isBundleRow" :class="antlerClass('times')">
                    <AppInputText
                        :disabled="loading > 0 || deleteItem || disabled"
                        :error-messages="itemFormErrors.startTime"
                        :label="$gettext('Starttijd')"
                        :required="!deleteItem && isFinal"
                        :rules="!deleteItem && isFinal ? timeRulesStart : []"
                        type="text"
                        validate-on-blur
                        :value="startTime"
                        @input="$emit('update:startTime', $event)"
                    />

                    <AppInputText
                        :disabled="
                            loading > 0 ||
                                !enableEndTime ||
                                deleteItem ||
                                disabled
                        "
                        :error-messages="itemFormErrors.endTime"
                        :hide-details="!enableEndTime"
                        :label="$gettext('Eindtijd')"
                        :required="!deleteItem && isFinal"
                        :rules="!deleteItem && isFinal ? timeRulesEnd : []"
                        type="text"
                        validate-on-blur
                        :value="endTime"
                        @input="$emit('update:endTime', $event)"
                    />
                </div>

                <AppInputSelect
                    v-model="subject"
                    :disabled="loading > 0 || deleteItem || disabled"
                    :error-messages="
                        !subject || subject.hasGrades
                            ? isBundle
                                ? itemFormErrors.subjectBundle
                                : itemFormErrors.subject
                            : []
                    "
                    :label="$gettext('Vak/Activiteit')"
                    :options="allSubjects"
                    :value="subject"
                    options-label="name"
                    options-track-by="id"
                    :required="!deleteItem && isFinal"
                >
                    <!-- prettier-ignore -->
                    <span slot="noOptions" v-translate>Geen vak/activiteit gevonden.</span>
                    <!-- prettier-ignore -->
                    <span slot="noResult" v-translate>Geen vak/activiteit gevonden.</span>
                </AppInputSelect>
            </div>

            <AppScheduleFormItemBundle
                v-if="isBundle && !deleteItem"
                :bundle-rows="bundleRows"
                :context="context"
                :form-errors="formErrors"
                :grades="grades"
                :disabled="loading > 0 || deleteItem || disabled"
                :is-final="isFinal"
                @addScheduleBundleRow="$emit('addScheduleBundleRow')"
            />
            <AppScheduleFormItemTemplate
                v-else-if="
                    (context === 'weekly' ||
                        (subject && subject.id === 'break')) &&
                        !isBundle
                "
                :form-errors="itemFormErrors"
                :notes="notes"
                :subject-description="subjectDescription"
                :subject-description-show="showSubjectDescription"
                :subject-description-require="requireSubjectDescription"
                :disabled="loading > 0 || deleteItem || disabled"
                @update:notes="$emit('update:notes', $event)"
                @update:subjectDescription="
                    $emit('update:subjectDescription', $event)
                "
            />
            <AppScheduleFormItemDefault
                v-else-if="
                    (!subject || subject.id !== 'break') &&
                        context !== 'weekly' &&
                        !isBundle
                "
                :disabled="loading > 0 || deleteItem || disabled"
                :form-errors="itemFormErrors"
                :theme="theme"
                :notes="notes"
                :purpose="purpose"
                :subject-id="subjectId"
                :sub-subject="subSubject"
                :subject-description="subjectDescription"
                :subject-description-show="showSubjectDescription"
                :subject-description-require="requireSubjectDescription"
                :sub-subjects-show="!!subject && subject.hasGrades === false"
                :sub-subjects-require="
                    !deleteItem &&
                        !!subject &&
                        subject.hasGrades === false &&
                        subject.subSubjectsAreOptional === false &&
                        isFinal
                "
                :sub-subjects-errors="
                    !!subject && subject.hasGrades === false
                        ? itemFormErrors.subject
                        : []
                "
                :sub-subject-id="subSubjectId"
                :grades="grades"
                @update:subSubject="subSubject = $event"
                @update:notes="$emit('update:notes', $event)"
                @update:theme="$emit('update:theme', $event)"
                @update:purpose="$emit('update:purpose', $event)"
                @update:subjectDescription="
                    $emit('update:subjectDescription', $event)
                "
            />
        </div>

        <AppDivider variant="vertical" />

        <div :class="antlerClass('options')">
            <AppButtonGroup variant="column between" gap="none">
                <AppButton
                    v-if="!isBundleRow"
                    variant="ghost draggable icon-small"
                    icon="move"
                />

                <AppInputCheckbox
                    class="u-margin-top-auto"
                    :disabled="
                        loading > 0 ||
                            disabled ||
                            (disableDelete && !deleteItem)
                    "
                    icon="trash"
                    icon-active-color="red"
                    hide-details
                    :value="deleteItem"
                    @change="$emit('update:deleteItem', $event)"
                />
            </AppButtonGroup>
        </div>
    </div>
</template>

<script>
import AppButton from "@/components/AppButton.vue";
import AppButtonGroup from "@/components/AppButtonGroup.vue";
import AppDivider from "@/components/AppDivider.vue";
import AppInputCheckbox from "@/components/AppInputCheckbox.vue";
import AppInputSelect from "@/components/AppInputSelect.vue";
import AppInputText from "@/components/AppInputText.vue";
import AppScheduleFormItemBundle from "@/components/AppScheduleFormItemBundle.vue";
import AppScheduleFormItemDefault from "@/components/AppScheduleFormItemDefault.vue";
import AppScheduleFormItemTemplate from "@/components/AppScheduleFormItemTemplate.vue";
import { antlerComponent } from "@/utils/antlerComponent";
import { defineComponent } from "vue";
import gql from "graphql-tag";

export default defineComponent({
    name: "AppScheduleFormItem",
    components: {
        AppScheduleFormItemBundle,
        AppScheduleFormItemDefault,
        AppScheduleFormItemTemplate,
        AppInputText,
        AppInputSelect,
        AppDivider,
        AppButton,
        AppInputCheckbox,
        AppButtonGroup
    },
    props: {
        context: {
            type: String,
            default: "schedule",
            validator: value => {
                return ["schedule", "weekly"].includes(value);
            }
        },
        deleteItem: Boolean,
        enableEndTime: Boolean,
        disabled: Boolean,
        disableDelete: Boolean,
        startTime: {
            type: String,
            default: ""
        },
        endTime: {
            type: String,
            default: ""
        },
        formIndex: {
            type: Number,
            required: true
        },
        formErrors: {
            type: Array,
            default: () => []
        },
        grades: {
            type: Array,
            required: true
        },
        purpose: {
            type: String,
            default: ""
        },
        subjectId: {
            type: String,
            default: undefined
        },
        subSubjectId: {
            type: String,
            default: undefined
        },
        subjectDescription: {
            type: String,
            default: ""
        },
        notes: {
            type: String,
            default: ""
        },
        theme: {
            type: String,
            default: ""
        },
        isBundle: Boolean,
        isBundleRow: Boolean,
        bundleRows: {
            type: Array,
            default: () => []
        },
        isFinal: Boolean
    },
    data() {
        return {
            loading: 0,
            allSubjects: [],
            subject: null,
            subSubject: null
        };
    },
    emits: [
        "update:subjectId",
        "update:subSubjectId",
        "update:subjectDescription",
        "update:startTime",
        "update:endTime",
        "update:notes",
        "update:theme",
        "update:purpose",
        "update:isBundle",
        "update:deleteItem",
        "addScheduleBundleRow"
    ],
    computed: {
        timeRules() {
            return [
                v =>
                    (!!v && this.$moment(v, "HHmm").isValid()) ||
                    this.$gettext("Vul een geldige tijd in")
            ];
        },
        timeRulesEnd() {
            return [
                ...this.timeRules,
                v =>
                    !this.startTime ||
                    !this.$moment(this.startTime, "HHmm").isValid() ||
                    this.$moment(v, "HHmm") >
                        this.$moment(this.startTime, "HHmm") ||
                    this.$gettext("Eindtijd moet hoger zijn dan starttijd")
            ];
        },
        timeRulesStart() {
            return [
                ...this.timeRules,
                v =>
                    !this.endTime ||
                    !this.$moment(this.endTime, "HHmm").isValid() ||
                    this.$moment(v, "HHmm") <
                        this.$moment(this.endTime, "HHmm") ||
                    this.$gettext("Starttijd moet lager zijn dan eindtijd")
            ];
        },
        showSubjectDescription() {
            return (
                !!this.subject &&
                (this.subject.id === "other" ||
                    (this.subject.hasGrades &&
                        !this.subject.parent &&
                        this.context !== "weekly"))
            );
        },
        requireSubjectDescription() {
            return (
                !this.deleteItem &&
                !!this.subject &&
                this.subject.id === "other"
            );
        },
        itemFormErrors() {
            return {
                startTime: [],
                endTime: [],
                subjectBundle: [],
                subject: [],
                subjectDescription: [],
                theme: [],
                purpose: [],
                notes: [],
                ...(this.formErrors[this.formIndex] || {})
            };
        }
    },
    apollo: {
        allSubjects: {
            query: gql`
                query($grades: [Int]!, $scheduleBundle: Boolean) {
                    allSubjects(
                        grades: $grades
                        isSubSubject: false
                        isSchedulable: true
                        isScheduleBundle: $scheduleBundle
                    ) {
                        edges {
                            node {
                                id
                                name
                                grades(first: 0) {
                                    pageInfo {
                                        hasNextPage
                                    }
                                }
                                parent {
                                    id
                                }
                                scheduleBundle
                                subSubjectsAreOptional(grades: $grades)
                            }
                        }
                    }
                }
            `,
            fetchPolicy: "cache-first",
            variables() {
                return {
                    grades: this.grades,
                    scheduleBundle: this.isBundleRow ? false : null
                };
            },
            update(data) {
                if (data.allSubjects) {
                    const subjects = [
                        ...data.allSubjects.edges.map(x => {
                            return {
                                ...x.node,
                                hasGrades: x.node.grades.pageInfo.hasNextPage
                            };
                        }),
                        {
                            id: "other",
                            name: this.$gettext(
                                "Ander vak/activiteit, namelijk"
                            ),
                            hasGrades: null,
                            parent: null
                        }
                    ];
                    if (!this.isBundleRow) {
                        subjects.push({
                            id: "break",
                            name: this.$gettext("Pauze"),
                            hasGrades: null,
                            parent: null
                        });
                    }
                    return subjects;
                } else {
                    return this.allSubjects;
                }
            },
            result({ loading }) {
                if (!loading) {
                    this.setSubject(this.subjectId);
                }
            }
        }
    },
    watch: {
        subject(newValue, oldValue) {
            if (
                oldValue === newValue ||
                (oldValue && newValue && oldValue.id === newValue.id)
            ) {
                return;
            }

            if (newValue && newValue.id) {
                switch (newValue.id) {
                    case "other":
                        this.$emit("update:subjectId", null);
                        this.$emit("update:subSubjectId", undefined);
                        break;
                    case "break":
                        this.$emit("update:subjectId", null);
                        this.$emit("update:subSubjectId", undefined);
                        this.$emit("update:subjectDescription", "");
                        break;
                    default:
                        this.$emit("update:subjectId", newValue.id);
                }
                this.$emit("update:isBundle", Boolean(newValue.scheduleBundle));
            } else {
                this.$emit("update:subjectId", undefined);
                this.$emit("update:subSubjectId", undefined);
                this.$emit("update:isBundle", false);
            }
        },
        subjectId(val) {
            this.setSubject(val);
        },
        subSubject(val) {
            this.$emit("update:subSubjectId", val ? val.id : null);
        }
    },
    methods: {
        setSubject(subjectId) {
            if (subjectId === undefined) return;
            if (subjectId) {
                this.subject = this.allSubjects.find(x => x.id === subjectId);
                return;
            }

            if (
                this.isBundleRow ||
                this.subjectDescription ||
                (this.subject && this.subject.id === "other")
            ) {
                this.subject = {
                    id: "other",
                    name: this.$gettext("Ander vak/activiteit, namelijk")
                };
            } else {
                this.subject = { id: "break", name: this.$gettext("Pauze") };
            }
        }
    },
    setup() {
        const { antlerClass } = antlerComponent("schedule-form-item");

        return {
            antlerClass
        };
    }
});
</script>

<style lang="sass">
.schedule-form-item
    display: flex
    padding: $spacing-regular

    border-radius: 6px
    background-color: #fff
    box-shadow: 1px 1px 2px rgba(#000, .16)

    .form-field-messages
        min-height: 0

        &:empty
            margin:
                top: 0
                bottom: 0

.schedule-form-item__body
    display: flex
    flex-direction: column
    flex-grow: 1
    gap: 16px

.schedule-form-item__top
    display: flex
    flex-grow: 1
    gap: 16px

.schedule-form-item__options
    margin: -8px

.schedule-form-item__times
    display: flex
    gap: 16px
    width: 270px

.schedule-form-item--break
    background-color: rgba(#fff, .5)

.schedule-form-item.sortable-ghost
    opacity: .5
    background-color: rgba($color-blue, .3)
    box-shadow: none

    .schedule-form-item,
    .schedule-form-item__body,
    .schedule-form-item__options,
    .divider
        opacity: 0

.schedule-form-item--bundle
    box-shadow: none
    background-color: $color-blue-lightest
    border:
        width: 1px
        style: solid
        color: $color-blue-lighter
</style>
