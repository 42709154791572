<template>
    <AppButton
        :class="antlerClass()"
        variant="link"
        icon="plus-circle"
        icon-size="small"
        icon-class=""
    >
        {{ label || $gettext("Voeg een vak of pauzeblok toe") }}
    </AppButton>
</template>

<script>
import AppButton from "@/components/AppButton.vue";
import { antlerComponent } from "@/utils/antlerComponent";
import { defineComponent } from "vue";

export default defineComponent({
    name: "AppScheduleFormItemMore",
    components: { AppButton },
    props: {
        label: {
            type: String,
            default: ""
        }
    },
    setup() {
        const { antlerClass } = antlerComponent("schedule-form-item-more");

        return {
            antlerClass
        };
    }
});
</script>

<style lang="sass">
.schedule-form-item-more
    display: flex
    align-items: center
    padding: 20px

    @include font-size(14px, 24px)
    color: $color-blue

    background-color: $color-blue-lightest
    border:
        width: 1px
        style: dashed
        color: $color-blue-light
        radius: 4px
    cursor: pointer

    &:hover
        border-color: $color-blue
</style>
