<template>
    <div :class="antlerClass()">
        <AppFormGroup v-if="subjectDescriptionShow" spacing="small">
            <AppInputText
                :disabled="disabled"
                :error-messages="formErrors.subjectDescription"
                :label="$gettext('Onderwerp')"
                type="text"
                :required="subjectDescriptionRequire"
                validate-on-blur
                :value="subjectDescription"
                @input="$emit('update:subjectDescription', $event)"
            />
        </AppFormGroup>

        <AppFormGroup spacing="small">
            <AppInputTextarea
                autosize
                size="xxsmall"
                :disabled="disabled"
                :error-messages="formErrors.notes"
                :label="$gettext('Vul een notitie voor de klas in')"
                :value="notes"
                @input="$emit('update:notes', $event)"
            />
        </AppFormGroup>
    </div>
</template>

<script>
import AppFormGroup from "@/components/AppFormGroup.vue";
import AppInputText from "@/components/AppInputText.vue";
import AppInputTextarea from "@/components/AppInputTextarea.vue";
import { antlerComponent } from "@/utils/antlerComponent";
import { defineComponent } from "vue";

export default defineComponent({
    name: "AppScheduleFormItemTemplate",
    components: { AppFormGroup, AppInputText, AppInputTextarea },
    props: {
        formErrors: {
            type: Object,
            default: () => ({
                notes: [],
                subjectDescription: []
            })
        },
        notes: {
            type: String,
            default: ""
        },
        subjectDescription: {
            type: String,
            default: ""
        },
        subjectDescriptionShow: Boolean,
        subjectDescriptionRequire: Boolean,
        disabled: Boolean
    },
    emits: ["update:notes", "update:subjectDescription"],

    setup() {
        const { antlerClass } = antlerComponent("schedule-form-item-template");

        return {
            antlerClass
        };
    }
});
</script>

<style lang="sass">
.schedule-form-item-template
    display: flex
    flex-direction: column
</style>
