<template>
    <div :class="antlerClass()">
        <slot />

        <AppScheduleFormItemMore
            :label="buttonLabel"
            @click.native="$emit('addScheduleRow')"
        />
    </div>
</template>

<script>
import AppScheduleFormItemMore from "@/components/AppScheduleFormItemMore.vue";
import { antlerComponent } from "@/utils/antlerComponent";
import { defineComponent } from "vue";

export default defineComponent({
    name: "AppScheduleFormItemGroup",
    components: { AppScheduleFormItemMore },
    emits: ["addScheduleRow"],
    props: {
        buttonLabel: {
            type: String,
            default: undefined
        }
    },
    setup() {
        const { antlerClass } = antlerComponent("schedule-form-item-group");

        return {
            antlerClass
        };
    }
});
</script>

<style lang="sass">
.schedule-form-item-group
    display: flex
    flex-direction: column

    .schedule-form-item
        margin-bottom: $spacing-regular

        &:last-child
            margin-bottom: 0

    *:not(:empty) + .schedule-form-item-more
        margin-top: $spacing-regular
</style>
