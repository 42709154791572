<template>
    <div :class="antlerClass()">
        <AppFormGroup spacing="small">
            <AppInputText
                :disabled="disabled"
                :error-messages="formErrors.theme"
                :label="$gettext('Les / thema')"
                type="text"
                :value="theme"
                @input="$emit('update:theme', $event)"
            />
        </AppFormGroup>

        <AppFormGroup spacing="small" variant="inline">
            <AppInputSelect
                v-show="subSubjectsShow"
                :value="subSubject"
                :disabled="disabled"
                :error-messages="subSubjectsErrors"
                :label="$gettext('Subvak')"
                :options="allSubSubjects"
                options-label="name"
                options-track-by="id"
                :required="subSubjectsRequire"
                :allow-empty="!subSubjectsRequire"
                :clearable="!subSubjectsRequire"
                validate-on-blur
                @input="$emit('update:subSubject', $event)"
            >
                <!-- prettier-ignore -->
                <span slot="noOptions" v-translate>Geen vak(ken) gevonden.</span>
                <!-- prettier-ignore -->
                <span slot="noResult" v-translate>Geen vak(ken) gevonden.</span>
            </AppInputSelect>

            <AppInputText
                v-show="subjectDescriptionShow"
                :disabled="disabled"
                :error-messages="formErrors.subjectDescription"
                :label="$gettext('Onderwerp')"
                type="text"
                :required="subjectDescriptionRequire"
                validate-on-blur
                :value="subjectDescription"
                @input="$emit('update:subjectDescription', $event)"
            />

            <AppInputText
                :disabled="disabled"
                :error-messages="formErrors.purpose"
                :label="$gettext('Vul het doel van de les in')"
                type="text"
                :value="purpose"
                @input="$emit('update:purpose', $event)"
            />
        </AppFormGroup>

        <AppFormGroup spacing="small">
            <AppInputTextarea
                autosize
                size="xxsmall"
                :disabled="disabled"
                :error-messages="formErrors.notes"
                :label="$gettext('Vul een notitie voor de klas in')"
                :value="notes"
                @input="$emit('update:notes', $event)"
            />
        </AppFormGroup>
    </div>
</template>

<script>
import AppFormGroup from "@/components/AppFormGroup.vue";
import AppInputSelect from "@/components/AppInputSelect.vue";
import AppInputText from "@/components/AppInputText.vue";
import AppInputTextarea from "@/components/AppInputTextarea.vue";
import { antlerComponent } from "@/utils/antlerComponent";
import { defineComponent } from "vue";
import gql from "graphql-tag";

export default defineComponent({
    name: "AppScheduleFormItemDefault",
    components: {
        AppInputSelect,
        AppFormGroup,
        AppInputText,
        AppInputTextarea
    },
    props: {
        deleteItem: Boolean,
        disabled: Boolean,
        formErrors: {
            type: Object,
            default: () => ({
                notes: [],
                theme: [],
                subjectDescription: [],
                purpose: []
            })
        },
        notes: {
            type: String,
            default: ""
        },
        theme: {
            type: String,
            default: ""
        },
        subSubject: {
            type: Object,
            default: null
        },
        loading: Boolean,
        purpose: {
            type: String,
            default: ""
        },
        subjectDescription: {
            type: String,
            default: ""
        },
        subjectDescriptionShow: Boolean,
        subjectDescriptionRequire: Boolean,
        subSubjectsShow: Boolean,
        subSubjectsRequire: Boolean,
        subSubjectsErrors: {
            type: Array,
            default: () => []
        },
        subjectId: {
            type: String,
            default: undefined
        },
        subSubjectId: {
            type: String,
            default: undefined
        },
        grades: {
            type: Array,
            required: true
        }
    },
    emits: [
        "update:notes",
        "update:theme",
        "update:subjectDescription",
        "update:purpose",
        "update:subSubject"
    ],
    data() {
        return {
            allSubSubjects: []
        };
    },
    setup() {
        const { antlerClass } = antlerComponent("schedule-form-item-template");

        return {
            antlerClass
        };
    },
    apollo: {
        allSubSubjects: {
            query: gql`
                query($parentId: ID!, $grades: [Int]!) {
                    allSubjects(
                        parentId: $parentId
                        grades: $grades
                        isSchedulable: true
                    ) {
                        edges {
                            node {
                                id
                                name
                            }
                        }
                    }
                }
            `,
            fetchPolicy: "cache-first",
            variables() {
                return {
                    parentId: this.subjectId,
                    grades: this.grades
                };
            },
            update(data) {
                if (data.allSubjects) {
                    return data.allSubjects.edges.map(x => x.node);
                } else {
                    return this.allSubSubjects;
                }
            },
            result({ loading }) {
                if (!loading) {
                    this.setSubSubject(this.subSubjectId);
                }
            },
            skip() {
                return !this.subjectId;
            }
        }
    },
    watch: {
        subSubject(val) {
            this.$emit("update:subSubjectId", val ? val.id : null);
        },
        subSubjectId(val) {
            this.setSubSubject(val);
        }
    },
    methods: {
        setSubSubject(subjectId) {
            this.$emit(
                "update:subSubject",
                subjectId
                    ? this.allSubSubjects.find(x => x.id === subjectId)
                    : undefined
            );
        }
    }
});
</script>
