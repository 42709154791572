var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.antlerClass('', {
            bundle: _vm.isBundleRow,
            break: _vm.subject && _vm.subject.id === 'break'
        })},[_c('div',{class:_vm.antlerClass('body')},[_c('div',{class:_vm.antlerClass('top')},[(!_vm.isBundleRow)?_c('div',{class:_vm.antlerClass('times')},[_c('AppInputText',{attrs:{"disabled":_vm.loading > 0 || _vm.deleteItem || _vm.disabled,"error-messages":_vm.itemFormErrors.startTime,"label":_vm.$gettext('Starttijd'),"required":!_vm.deleteItem && _vm.isFinal,"rules":!_vm.deleteItem && _vm.isFinal ? _vm.timeRulesStart : [],"type":"text","validate-on-blur":"","value":_vm.startTime},on:{"input":function($event){return _vm.$emit('update:startTime', $event)}}}),_c('AppInputText',{attrs:{"disabled":_vm.loading > 0 ||
                            !_vm.enableEndTime ||
                            _vm.deleteItem ||
                            _vm.disabled,"error-messages":_vm.itemFormErrors.endTime,"hide-details":!_vm.enableEndTime,"label":_vm.$gettext('Eindtijd'),"required":!_vm.deleteItem && _vm.isFinal,"rules":!_vm.deleteItem && _vm.isFinal ? _vm.timeRulesEnd : [],"type":"text","validate-on-blur":"","value":_vm.endTime},on:{"input":function($event){return _vm.$emit('update:endTime', $event)}}})],1):_vm._e(),_c('AppInputSelect',{attrs:{"disabled":_vm.loading > 0 || _vm.deleteItem || _vm.disabled,"error-messages":!_vm.subject || _vm.subject.hasGrades
                        ? _vm.isBundle
                            ? _vm.itemFormErrors.subjectBundle
                            : _vm.itemFormErrors.subject
                        : [],"label":_vm.$gettext('Vak/Activiteit'),"options":_vm.allSubjects,"value":_vm.subject,"options-label":"name","options-track-by":"id","required":!_vm.deleteItem && _vm.isFinal},model:{value:(_vm.subject),callback:function ($$v) {_vm.subject=$$v},expression:"subject"}},[_c('span',{directives:[{name:"translate",rawName:"v-translate"}],attrs:{"slot":"noOptions"},slot:"noOptions"},[_vm._v("Geen vak/activiteit gevonden.")]),_c('span',{directives:[{name:"translate",rawName:"v-translate"}],attrs:{"slot":"noResult"},slot:"noResult"},[_vm._v("Geen vak/activiteit gevonden.")])])],1),(_vm.isBundle && !_vm.deleteItem)?_c('AppScheduleFormItemBundle',{attrs:{"bundle-rows":_vm.bundleRows,"context":_vm.context,"form-errors":_vm.formErrors,"grades":_vm.grades,"disabled":_vm.loading > 0 || _vm.deleteItem || _vm.disabled,"is-final":_vm.isFinal},on:{"addScheduleBundleRow":function($event){return _vm.$emit('addScheduleBundleRow')}}}):(
                (_vm.context === 'weekly' ||
                    (_vm.subject && _vm.subject.id === 'break')) &&
                    !_vm.isBundle
            )?_c('AppScheduleFormItemTemplate',{attrs:{"form-errors":_vm.itemFormErrors,"notes":_vm.notes,"subject-description":_vm.subjectDescription,"subject-description-show":_vm.showSubjectDescription,"subject-description-require":_vm.requireSubjectDescription,"disabled":_vm.loading > 0 || _vm.deleteItem || _vm.disabled},on:{"update:notes":function($event){return _vm.$emit('update:notes', $event)},"update:subjectDescription":function($event){return _vm.$emit('update:subjectDescription', $event)}}}):(
                (!_vm.subject || _vm.subject.id !== 'break') &&
                    _vm.context !== 'weekly' &&
                    !_vm.isBundle
            )?_c('AppScheduleFormItemDefault',{attrs:{"disabled":_vm.loading > 0 || _vm.deleteItem || _vm.disabled,"form-errors":_vm.itemFormErrors,"theme":_vm.theme,"notes":_vm.notes,"purpose":_vm.purpose,"subject-id":_vm.subjectId,"sub-subject":_vm.subSubject,"subject-description":_vm.subjectDescription,"subject-description-show":_vm.showSubjectDescription,"subject-description-require":_vm.requireSubjectDescription,"sub-subjects-show":!!_vm.subject && _vm.subject.hasGrades === false,"sub-subjects-require":!_vm.deleteItem &&
                    !!_vm.subject &&
                    _vm.subject.hasGrades === false &&
                    _vm.subject.subSubjectsAreOptional === false &&
                    _vm.isFinal,"sub-subjects-errors":!!_vm.subject && _vm.subject.hasGrades === false
                    ? _vm.itemFormErrors.subject
                    : [],"sub-subject-id":_vm.subSubjectId,"grades":_vm.grades},on:{"update:subSubject":function($event){_vm.subSubject = $event},"update:notes":function($event){return _vm.$emit('update:notes', $event)},"update:theme":function($event){return _vm.$emit('update:theme', $event)},"update:purpose":function($event){return _vm.$emit('update:purpose', $event)},"update:subjectDescription":function($event){return _vm.$emit('update:subjectDescription', $event)}}}):_vm._e()],1),_c('AppDivider',{attrs:{"variant":"vertical"}}),_c('div',{class:_vm.antlerClass('options')},[_c('AppButtonGroup',{attrs:{"variant":"column between","gap":"none"}},[(!_vm.isBundleRow)?_c('AppButton',{attrs:{"variant":"ghost draggable icon-small","icon":"move"}}):_vm._e(),_c('AppInputCheckbox',{staticClass:"u-margin-top-auto",attrs:{"disabled":_vm.loading > 0 ||
                        _vm.disabled ||
                        (_vm.disableDelete && !_vm.deleteItem),"icon":"trash","icon-active-color":"red","hide-details":"","value":_vm.deleteItem},on:{"change":function($event){return _vm.$emit('update:deleteItem', $event)}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }