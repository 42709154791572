<template>
    <AppScheduleFormItemGroup
        :class="antlerClass()"
        :button-label="$gettext('Voeg een vak toe aan deze bundel')"
        @addScheduleRow="$emit('addScheduleBundleRow')"
    >
        <div :class="antlerClass('body')">
            <AppScheduleFormItem
                v-for="row in bundleRows"
                :key="`bundle-${row.index}-${row.id}`"
                :grades="grades"
                :context="context"
                :delete-item="row.delete"
                :disable-delete="disableDelete"
                :disabled="loading || disabled"
                :form-index="row.index"
                :form-errors="formErrors"
                is-bundle-row
                :notes.sync="row.notes"
                :theme.sync="row.theme"
                :purpose.sync="row.purpose"
                :subject-description.sync="row.subjectDescription"
                :subject-id.sync="row.subjectId"
                :sub-subject-id.sync="row.subSubjectId"
                :is-final="isFinal"
                @update:deleteItem="handleDeleteItem(row, $event)"
            />
        </div>
    </AppScheduleFormItemGroup>
</template>

<script>
import AppScheduleFormItemGroup from "@/components/AppScheduleFormItemGroup.vue";
import { antlerComponent } from "@/utils/antlerComponent";
import { defineComponent } from "vue";

export default defineComponent({
    name: "AppScheduleFormItemBundle",
    components: {
        AppScheduleFormItem: () =>
            import("@/components/AppScheduleFormItem.vue"),
        AppScheduleFormItemGroup
    },
    props: {
        bundleRows: {
            type: Array,
            required: true
        },
        context: {
            type: String,
            required: true
        },
        formErrors: {
            type: Array,
            default: () => []
        },
        grades: {
            type: Array,
            required: true
        },
        loading: Boolean,
        disabled: Boolean,
        isFinal: Boolean
    },
    emits: ["addScheduleBundleRow"],
    data() {
        return {
            disableDelete: false
        };
    },
    setup() {
        const { antlerClass } = antlerComponent("schedule-form-item-bundle");

        return {
            antlerClass
        };
    },
    watch: {
        bundleRows: {
            handler() {
                this.setDisableDelete();
            },
            immediate: true
        }
    },
    methods: {
        handleDeleteItem(row, event) {
            row.delete = event;
            this.setDisableDelete();
        },
        setDisableDelete() {
            this.disableDelete =
                this.bundleRows.filter(x => !x.delete).length <= 1;
        }
    }
});
</script>
